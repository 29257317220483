import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <h6>Ouro Card</h6>
  );
}

export default Footer;
